@import "../../theme/_mixins.scss";

:global {
  .swiper-button-next,
  .swiper-button-prev {
    color: #61481c;
  }
}

@include home-section-container;
@include home-section-title;

.sliderContainer {
  @include devices(md) {
    // Add padding for slider offset
    padding-right: 60px;
  }

  @include devices(sm) {
    padding-right: 40px;
  }

  @include devices(xs) {
    padding-right: 30px;
  }

  .slideItem {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
    cursor: pointer;
    overflow: hidden;

    .slideImageContainer {
      overflow: hidden;
      height: 150px;

      @include devices(sm) {
        height: 140px;
      }

      @include devices(xs) {
        height: 130px;
      }

      img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        transform: scale(1.75);
        opacity: 0.25;
        transition: all 0.3s ease-in-out;
      }
    }

    .slideContentContainer {
      background: #f0bb62;
      padding: 20px 10px;
      transition: all 0.3s ease-in-out;
      text-align: center;

      p {
        color: #61481c;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease-in-out;
      }

      @include devices(md) {
        padding: 15px 5px;

        p {
          font-size: 14px;
        }
      }
    }

    &:hover {
      .slideImageContainer {
        img {
          transform: scale(1);
          opacity: 1;
        }
      }

      .slideContentContainer {
        background: #61481c;

        p {
          color: #fff;
        }
      }
    }
  }
}
