@import "../../theme/_mixins.scss";

:global {
  .lunbo-slider {
    .swiper-button-next,
    .swiper-button-prev {
      color: #fff;
    }
  }
}

.slideItem {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 350px;
  color: #fff;

  @include devices(md) {
    height: 350px;
  }

  @include devices(sm) {
    height: 320px;
  }

  @include devices(xs) {
    height: 280px;
  }
}

.slideShade {
  background: linear-gradient(
    270deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(0, 0, 0, 0.68) 51.04%
  );
  position: absolute;
  top: 0;
  height: 100px;
  width: 68%;

  @include devices(md) {
    height: 70px;
    width: 75%;
  }

  @include devices(sm) {
    height: 60px;
    width: 85%;
  }

  @include devices(xs) {
    width: 95%;
  }

  @include devices(xxs) {
    height: 70px;
  }
}

.slideContent {
  padding-top: 15px;
  padding-left: 100px;

  h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 1;
  }

  p {
    font-size: 25px;
    line-height: 1;
  }

  @include devices(md) {
    padding-top: 8px;
    padding-left: 40px;

    h2 {
      font-size: 28px;
    }
    p {
      font-size: 21px;
    }
  }

  @include devices(sm) {
    padding-left: 20px;

    h2 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }

  @include devices(xxs) {
    padding-left: 15px;

    h2 {
      font-size: 21px;
    }
    p {
      font-size: 16px;
    }
  }
}

.position {
  position: absolute;
  width: 100%;

  &.topLeft {
    top: 50px;
  }

  &.bottomLeft {
    bottom: 150px;
  }

  &.topRight {
    .slideShade {
      background: linear-gradient(
        90deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(0, 0, 0, 0.68) 51.04%
      );
      top: 50px;
      right: 0;
    }

    .slideContent {
      padding-right: 100px;
      text-align: right;
    }
  }

  &.bottomRight {
    bottom: 150px;

    .slideShade {
      background: linear-gradient(
        90deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(0, 0, 0, 0.68) 51.04%
      );
      right: 0;
    }

    .slideContent {
      padding-right: 100px;
      text-align: right;
    }
  }

  @include devices(md) {
    &.topLeft {
      top: 40px;
    }

    &.bottomLeft {
      bottom: 110px;
    }

    &.topRight {
      .slideShade {
        top: 40px;
      }

      .slideContent {
        padding-right: 40px;
      }
    }

    &.bottomRight {
      bottom: 110px;

      .slideContent {
        padding-right: 40px;
      }
    }
  }

  @include devices(sm) {
    &.topLeft {
      top: 30px;
    }

    &.bottomLeft {
      bottom: 90px;
    }

    &.topRight {
      .slideShade {
        top: 30px;
      }

      .slideContent {
        padding-right: 20px;
      }
    }

    &.bottomRight {
      bottom: 90px;

      .slideContent {
        padding-right: 20px;
      }
    }
  }

  @include devices(xxs) {
    &.topRight,
    &.bottomRight {
      .slideContent {
        padding-right: 15px;
      }
    }
  }
}
