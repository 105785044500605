.navigation {
  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li:not(:last-child) {
      margin-bottom: 20px;
    }

    a {
      display: block;
      background: #dddcdc;
      border: 0.5px solid #61481c;
      border-radius: 10px;
      color: #000000;
      font-size: 18px;
      line-height: 1;
      padding: 25px 0;
      text-align: center;
      text-decoration: none;
    }
  }
}
