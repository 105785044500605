@import "../../../theme/_variables.scss";
@import "../../../theme/_mixins.scss";

.container {
  min-height: calc(100vh - $FM_HEADER_HEIGHT - $FM_FOOTER_HEIGHT);
  padding: 40px 0;
}

.navigationContainer,
.contentContainer {
  background: #f2f2f2;
  border: 1px solid #000000;
  height: 100%;
  padding: 30px 20px;
}

.contentContainer {
  background-color: #fdf5e7;
}

.content {
  height: 100%;
  padding: 30px 20px;
}
