.account-container {
  .account-container-2 {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-bottom: 30px;

    p {
      font-weight: 400;
      font-size: 15px;
      margin-top: 10px;
    }

    input {
      margin-left: 20px;
      width: 583px;
      height: 65px;
      background: #d9d9d9;
      border-radius: 5px;
      border: none;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    text-align: center;
    margin-left: 195px;

    button {
      width: 138px;
      height: 31px;
      background: #d9d9d9;
      border: 0.5px solid rgba(97, 72, 28, 0.5);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      text-transform: uppercase;
    }
  }
}
