@import "../theme/_variables.scss";

.orderTracker {
  margin-top: $FM_HEADER_HEIGHT;
  min-height: calc(100vh - $FM_HEADER_HEIGHT - $FM_FOOTER_HEIGHT);

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .container {
    width: 100%;
    position: relative;
  }

  .container .title {
    text-align: center;
    // padding: 40px;
  }

  .container .title h1 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: #61481c;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }

  .container .title p {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 1;
    text-align: center;
    color: #61481c;
  }

  .container .images {
    top: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    gap: 70px;
  }

  .container .images img {
    width: 200px;
    height: 180px;
  }

  .container .images .image {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }

  .container .images .image label {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #000000;
  }

  .container .ty {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  .container .ty a {
    background: linear-gradient(180deg, #bf9742 0%, #61481c 100%);
    padding: 1em 5em;
    border: 1px solid #61481c;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
}
