@import "../../theme/_mixins.scss";

:global {
  .swiper-button-next,
  .swiper-button-prev {
    color: #61481c;
  }
}

@include home-section-container;
@include home-section-title;

.slideItem {
  border: 1px solid #969696;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  padding: 20px;
}

.slideImageContainer {
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
}

.slideContentContainer {
  padding: 20px 0 0;

  p {
    color: #000;
    margin-bottom: 0;
    text-align: left;
    transition: all 0.3s ease-in-out;

    &.slideTitle {
      font-size: 16px;
      line-height: 20px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      height: 40px;
      margin-bottom: 5px;
    }

    &.slideDescription {
      font-size: 14px;
      line-height: 17px;
      height: 34px;
      margin-bottom: 40px;

      /* Line clamp style for 2 lines */
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .slideLink {
    display: block;
    color: #fff;
    background: #61481c;
    border: 1px solid #f0bb62;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px;
    transition: all 0.3s ease-in-out;

    &:hover {
      border: 1px solid black;
      background: #f0bb62;
      color: #61481c;
    }
  }
}

.restaurantGrid {
  margin: 0 10px;

  .sliderContainer {
    width: 33%;
  }

  .slideItem {
    margin: 0 10px 20px 10px;
    padding: 15px;
  }

  .slideImageContainer {
    img {
      height: 150px;
    }
  }

  @include devices(sm) {
    .sliderContainer {
      width: 50%;
    }

    .slideLink {
      font-size: 14px;
    }
  }

  @include devices(xxs) {
    margin: 0 8px;

    .slideItem {
      margin: 0 8px 16px 8px;
      padding: 10px;
    }

    .slideContentContainer {
      p {
        &.slideTitle {
          font-size: 15px;
        }

        &.slideDescription {
          font-size: 13px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
