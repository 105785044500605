.row {
  padding: 10px;
  margin-top: 5px;
}

.foodCarousel {
  h3 {
    font-size: 1.2em;
  }

  .multi-carousel-item {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
    overflow: hidden;
  }

  .multi-carousel-item .multi-carousel-image {
    overflow: hidden;
    border-radius: 10px;
  }

  .multi-carousel-item .multi-carousel-image img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border: 2px solid #61481c;
    border-radius: 10px;
  }

  .multi-carousel-item .multi-carousel-content {
    width: 100%;
    background: white;
    padding: 5px 0 20px;
    transition: all 0.3s ease-in-out;
    border: 2px solid #61481c;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .multi-carousel-content .content {
    padding: 10px;
  }

  .multi-carousel-content .price {
    margin-top: 2px;
    padding: 10px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }

  .multi-carousel-content .button-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 25px;
  }

  .multi-carousel-content .number-input {
    display: flex;
    gap: 10px;
    padding: 10px;

    background: #dedede;
    border: 0.5px solid #6b6b6a;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 25%);
    padding: 10px 20px;
  }

  .multi-carousel-content .number-input button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .multi-carousel-content .number-input p {
  }

  .multi-carousel-content .add-to-cart {
    background: #e6b325;
    border: 0.5px solid #61481c;
    border-radius: 5px;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 25%);
    padding: 10px 20px;
  }

  .multi-carousel-content .add-to-cart button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .multi-carousel-content .add-to-cart button span {
    color: #ffffff;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
    text-transform: uppercase;
  }
}
