@import "../../theme/_mixins.scss";

.logo {
  height: 40px;
  position: relative;

  a {
    img {
      width: auto;
      height: 100%;
      transition: all 0.3s ease-in-out;

      &.logoHover {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    &:hover {
      img {
        &.logoMain {
          opacity: 0;
        }

        &.logoHover {
          opacity: 1;
        }
      }
    }
  }
}

.formInnerContainer {
  background: rgba(198, 174, 133, 0.5);
  border-radius: 15px;
  margin-bottom: 20px;
  padding: 40px;

  @include devices(md) {
    background: none;
    padding: 0 20px;

    &:first-child {
      margin-bottom: 40px;
    }
  }
}

.form {
  text-align: center;

  h3 {
    color: #61481c;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  label {
    position: absolute;
    color: #61481c;
    font-size: 12px;
    line-height: 1;
    opacity: 0.5;
    top: 5px;
    left: 10px;
  }

  input {
    background: #f8f2e9;
    border: 1px solid rgba(97, 72, 28, 0.5);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px 10px 5px;
  }

  button {
    background: #e6b325;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 5px;
    color: #61481c;
    font-size: 20px;
    padding: 10px 70px;

    &:hover {
      background: #a47e3b;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .checkbox {
    margin-top: 30px;
    padding: 0 20px;

    > div {
      height: 28px;
      line-height: 28px;
      font-size: 16px;
      margin-bottom: 0;
    }

    label {
      color: #bf9742;
      font-size: 16px;
      line-height: 1;
      position: initial;
      margin-left: 10px;
    }
  }
}
