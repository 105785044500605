.row {
  padding: 10px;
  margin-top: 5px;
  overflow: hidden;
}

.multi-carousel-item {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
  overflow: hidden;
}

.multi-carousel-item .multi-carousel-image {
  overflow: hidden;
  border-radius: 10px;
}

.multi-carousel-item .multi-carousel-image img {
  width: 100%;
  height: 90px;
  object-fit: cover;
  border: 2px solid black;
  border-radius: 10px;
}
