* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1300px;
  // height: 50vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .left {
  height: 944px;
  background: #f2f0f0;
  border: 1px solid #000000;
}

.container .right {
  height: 944px;
  background: #e3d7c2;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
}

.container .left li {
  list-style-type: none;
}

.container .list-1 {
  display: flex;
}

.container .list-2 {
  display: flex;
}

.container .list-3 {
  display: flex;
}

.container .left .filters {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #61481c;
}

.switch {
  position: relative;
  display: inline-block;
  width: 43px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.container .left .list {
  padding: 20px;
}

.container .left .list h3 {
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: #61481c;
  margin-bottom: 10px;
}

.container .left .list .list-1,
.container .left .list .list-2,
.container .left .list .list-3,
.container .left .list .list-4 {
  padding: 0.25em;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #61481c;
  gap: 10px;
  margin-left: 10px;
}

.list-1 input {
  background: linear-gradient(180deg, #9c9c9c 0%, #5d5d5d 100%);
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.container .left .list .secondList {
  margin-top: 10px;
}

.container .right {
  padding: 20px;
}

.container .right .rightDiv-1 {
  display: flex;
  gap: 10px;
}

.container .right .rightDiv-1 img {
  width: 78px;
  height: 80px;
  margin-left: 5px;
}

.container .right .rightDiv-1 h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #61481c;
}

.container .right .rightDiv-1 p {
  font-size: 8px;
  line-height: 8px;
  font-weight: 400;
  font-style: normal;
  color: #61481c;
}

.container .right .rightDiv-1-buttons {
  display: flex;
  flex-direction: column;
  // margin-left: 700px;
  gap: 10px;
}

.container .right .rightDiv-1-buttons #memberBtn {
  background: #868585;
  padding: 1em 2.5em;
  border: 0.5px solid #61481c;
  border-radius: 5px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
}

.container .right .rightDiv-1-buttons #postedBtn {
  background: white;
  padding: 1em 2.5em;
  border: 0.5px solid #61481c;
  border-radius: 5px;
  color: #969696;
  font-weight: 300;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
}

.container .right .cat-promos img {
  width: 170px;
  height: 130px;
  border: 1px solid #969696;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}

.container .right .ordered-items {
  position: relative;
  height: 192px;
  background: #e3d7c2;
  border: 1px solid #000000;
  padding: 10px;
}

.container .right .ordered-items h4 {
  padding: 10px;
}
.container .right .ordered-items {
  width: 55%;
}

.container .right .ordered-items .ordered-items-images {
  position: relative;
  display: flex;
  padding: 5px;
}

.ordered-items-images .ordered-items-choices {
  display: flex;
  gap: 20px;
  padding: 5px;
}

.container .right .ordered-items .ordered-items-images img {
  width: 120px;
  height: 70px;
  overflow: hidden;

  border: 0.5px solid #61481c;
  border-radius: 5px;
}

.ordered-items-images .ordered-items-buttons-1 {
  position: absolute;
  bottom: -14px;
  left: 20px;
  display: flex;
  gap: 10px;
  margin-left: 40px;
}

.ordered-items-images .ordered-items-buttons-2 {
  position: absolute;
  bottom: -14px;
  left: 180px;
  display: flex;
  gap: 10px;
  margin-left: 40px;
}

.ordered-items-images .ordered-items-buttons-3 {
  position: absolute;
  bottom: -14px;
  left: 340px;
  display: flex;
  gap: 10px;
  margin-left: 40px;
}

.ordered-items-images .ordered-items-buttons-4 {
  position: absolute;
  bottom: -14px;
  left: 500px;
  display: flex;
  gap: 10px;
  margin-left: 40px;
}

.ordered-items-images .ordered-items-choices button {
  background: none;
  border: none;
  cursor: pointer;
}

.ordered-items-images .ordered-items-choices p {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.container .right .checkout {
  position: relative;
  // top: -172px;
  // left: 648px;
  // width: 692px;
  height: 192px;
  background: #f2f0f0;
  border: 1px solid #000000;
}

.checkout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
}

.checkout .checkout-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
}

.checkout .checkout-left span {
  float: right;
  margin-left: 0px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin-top: 5px;
}

.checkout .checkout-left .total-padding {
  background: #e6b325;
  border: 1px solid #61481c;
  border-radius: 5px;
}

.checkout .checkout-left #total {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 5px;

  a {
    margin-right: 60px;
  }

  span {
    margin-top: 0;
  }
}

.checkout .checkout-left #total span {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.checkout .checkout-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-right: 20px;
  text-align: center;
}

.checkout .checkout-right #checkoutBtn {
  background: #e6b325;
  padding: 8px 12px;
  border: 1px solid #584016;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.checkout .checkout-right #cancelBtn {
  background: #bf9520;
  padding: 8px 12px;
  border: 1px solid #584016;
  border-radius: 5px;
  cursor: pointer;
}

.menuContainer {
  * {
    margin: 0;
    padding: 0;
  }

  h4 {
    font-size: 16px;
  }
}
