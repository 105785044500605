:global {
  .offcanvas {
    background: transparent;
    border: none !important;
    box-shadow: none;
    width: auto !important;
    height: auto;
    top: 15px !important;
    right: 15px !important;

    .offcanvas-body {
      background-color: #d9d9d9;
      flex-grow: 0;
      padding: 0;
      width: auto;
    }
  }
}

.burger {
  background: #e6b325;
  border: 0.5px solid #61481c;
  border-radius: 3px;
  cursor: pointer;
  line-height: 1;
}

.menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;

  li:not(:last-child) {
    border-bottom: 0.5px solid #555454;
  }

  li:not(:first-child) {
    border-top: 0.5px solid #acacac;
  }

  a {
    color: #61481c;
    display: inline-block;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    padding: 10px 25px;
    transition: all 0.3s ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
}
