/*
  ##################################################
  Breakpoints reference: https://getbootstrap.com/docs/5.0/layout/breakpoints/
  NOTE: XXS is custom
  
  RESPONSIVE BOILERPLATE:

  @include devices(md) {
    // Styles here ...
  }

  @include devices(sm) {
    // Styles here ...
  }

  @include devices(xs) {
    // Styles here ...
  }

  @include devices(xxs) {
    // Styles here ...
  }
  ##################################################
*/
@mixin devices($breakpoint) {
  @if $breakpoint == md {
    @media only screen and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint == xs {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }

  @if $breakpoint == xxs {
    @media only screen and (max-width: 480px) {
      @content;
    }
  }
}

/*
  ##################################################
  Home page mixins
  ##################################################
*/
@mixin home-section-container {
  .container {
    margin: 40px auto;

    @include devices(md) {
      margin: 30px auto;
      padding: 0;
    }
  }
}

@mixin home-section-title {
  .sectionTitle {
    color: #61481c;
    font-size: 25px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

    @include devices(md) {
      text-align: center;
    }

    @include devices(sm) {
      font-size: 21px;
    }
  }
}
