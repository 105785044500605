@import "../../theme/_variables.scss";
@import "../../theme/_mixins.scss";

.container {
  overflow-x: hidden;
  min-height: calc(100vh - $FM_HEADER_HEIGHT - $FM_FOOTER_HEIGHT);
  padding-top: 40px;

  @include devices(md) {
    min-height: auto;
    margin-bottom: 40px + $FM_FOOTER_HEIGHT_MD;
  }
}
